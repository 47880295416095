import { meCreateCheckHandler } from '~/modules/SDK/me/meCreateCheckHandler';
/**
 * - 給我「productName」換回一個「checkHandler」
 * - 會檢查 expiredAt 參數
 *
 * @example
 *   // 飯粒
 *   export const meCheckHandlerAgentWeb = meCheckHandlerCreateByProductNameWithExpiredAt('web')
 */
export const meCheckHandlerCreateByProductNameWithExpiredAt = (productName) => meCreateCheckHandler(scope => {
    const subscriptions = scope.meUserState?.subscriptions?.find(item => {
        const agent = item.agentName === scope.agentName;
        const product = item.productName === productName;
        return agent && product && !item.isExpired;
    });
    return !!subscriptions;
});
/**
 * 在 asiajye 的案例中，同一個 agent 版塊會有多重權限
 *
 * - 給我「兩個 productName」換回一個「checkHandler」
 * - 會檢查 expiredAt 參數
 *
 * @example
 *   //
 *   // asiajye 的期貨版，`web` 或 `web_pro` 皆可進入版面
 *   //
 *   export const meCheckHandlerAgentWeb = meCheckHandlerCreateByProductNameWithExpiredAt([
 *     'web',
 *     'web_pro',
 *   ])
 *
 *   //
 *   // asiajye 的期貨版，`web_stock` 或 `web_stock_pro` 皆可進入版面
 *   //
 *   export const meCheckHandlerAgentWebStock = meCheckHandlerCreateByProductNameWithExpiredAt([
 *     'web_stock',
 *     'web_stock_pro',
 *   ])
 */
export const meCheckHandlerCreateByProductNamesWithExpiredAt = (productNames) => meCreateCheckHandler(scope => {
    return (meCheckHandlerCreateByProductNameWithExpiredAt(productNames[0]).check() ||
        meCheckHandlerCreateByProductNameWithExpiredAt(productNames[1]).check());
});
/**
 * - 給我「productName」換回一個「checkHandler」
 * - 不會檢查 expiredAt 參數，因為給「交易策略」開後門，不檢察過期
 */
export const meCheckHandlerCreateByProductNameOnStrategy = (productName) => meCreateCheckHandler(scope => {
    const found = scope.meUserState?.subscriptions.find(item => {
        const agent = item.agentName === scope.agentName;
        const product = item.productName === productName;
        return agent && product;
    });
    return found !== undefined;
});
/**
 * 為「交易策略」列舉的獨立權限檢查函式
 *
 * #@deprecated
 *
 *      > 後來就只驗 `futuresai@web` 了，所以這個沒用了
 *
 * @deprecated
 */
export const meCheckHandlerStrategy = {
    _MASS_SEAN: meCheckHandlerCreateByProductNameOnStrategy('_MASS_SEAN'),
    _OSC_SEAN: meCheckHandlerCreateByProductNameOnStrategy('_OSC_SEAN'),
    _RVGI: meCheckHandlerCreateByProductNameOnStrategy('_RVGI'),
    _PIVOT_REVERSE_MHTSAI: meCheckHandlerCreateByProductNameOnStrategy('_PIVOT_REVERSE_MHTSAI'),
    _VWMA_: meCheckHandlerCreateByProductNameOnStrategy('_VWMA_'),
    _SWING_V2: meCheckHandlerCreateByProductNameOnStrategy('_SWING_V2'),
};
